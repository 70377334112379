import React, { Fragment } from "react"
import { graphql } from "gatsby"
import productBannerImg from "../assets/images/product-banner.jpg"
import bikeBannerImg from "../assets/images/bike-banner.jpg"
import carBannerImg from "../assets/images/car-banner.jpg"
import gearBannerImg from "../assets/images/gear-banner.jpg"
import tractorBannerImg from "../assets/images/tractor-banner.jpg"
import { Accordion, Breadcrumb, Col, Container, Row } from "react-bootstrap"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
import { FaDownload } from "react-icons/fa"
import { v4 as uuidv4 } from "uuid"
import titanicDefaultImg from "../assets/images/titanic-default.png"
import gypsolDefaultImg from "../assets/images/gypsol-default.png"

const pdfsList = {
  bike: "bike-engine-oil.pdf",
  car: "car-engine-oil.pdf",
  gas: "gas-engine-oil.pdf",
  gear: "gear-oil.pdf",
  hvco: "hvco.pdf",
  industrial: "industrial-fluids.pdf",
  tractor: "tractor-oils.pdf",
  utility: "utility-vehicle.pdf",
}

const bannerImgList = {
  bike: bikeBannerImg,
  car: carBannerImg,
  // gas:'gas-engine-oil.pdf',
  gear: gearBannerImg,
  // hvco:'hvco.pdf',
  // industrial:'industrial-fluids.pdf',
  tractor: tractorBannerImg,
  // utility:'utility-vehicle.pdf',
}

function getBrochurePdf(val) {
  val = val.split(":")
  return pdfsList[val[0]]
}

function getProductBanner(val) {
  val = val.split(":")
  return bannerImgList[val[0]]
}

const ProjectDetails = ({ data }) => {
  const product = data.allWpProduct.nodes[0]
  // console.log(product)
  const showProductImage =
    product.productImage && product.productImage.productImage
  const showAccordion =
    product.productDescription.description ||
    (product.productApplications &&
      product.productApplications.productApplications)
  const showProductApplications =
    product.productApplications &&
    product.productApplications.productApplications
  const productSubCategory =
    product.productSubCategory && product.productSubCategory.productSubCategory
  const productAvlSizes =
    product.productPackSizes && product.productPackSizes.availablePackSizes
  let brochurePdf
  let bannerImg
  let imgUrl

  if (showProductImage) {
    imgUrl = product.productImage.productImage.sourceUrl
  } else if (productSubCategory) {
    imgUrl = titanicDefaultImg
  } else {
    imgUrl = gypsolDefaultImg
  }

  if (productSubCategory) {
    brochurePdf = getBrochurePdf(productSubCategory)
    bannerImg = getProductBanner(productSubCategory)
  }

  return (
    <LayoutAnimate>
      <section className="page-banner-section">
        <Parallax
          bgImage={bannerImg || productBannerImg}
          bgImageAlt="Call to action"
          strength={200}
          className="cta-section"
          renderLayer={percentage => (
            <div
              style={{
                position: "absolute",
                background: `linear-gradient(to right, rgba(2, 23, 32,1),rgba(2, 23, 32,0.7),rgba(2, 23, 32,0.2))`,
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        >
          <div className="page-banner-wrapper">
            <Container
              className="page-banner-title-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay="200"
            >
              <h2>{product.title}</h2>
            </Container>
            <div className="breadcrumnb-wrapper">
              <Container
                className="breadcrumb-container pb-3"
                data-sal-duration="1000"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="400"
              >
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  {/* <Breadcrumb.Item href="/">Products</Breadcrumb.Item> */}
                  <Breadcrumb.Item active>{product.title}</Breadcrumb.Item>
                </Breadcrumb>
              </Container>
            </div>
          </div>
        </Parallax>
      </section>
      {/* product detail section */}
      <section className="pdtl-section layout-padding2">
        <Container>
          {/* <h3 className="mb-4">{product.title}</h3> */}
          <Row className="align-items-center">
            <Col md={6} lg={5}>
              <div className="pdtl-img-box">
                <img src={imgUrl} alt={`${product.title}`} />
              </div>
            </Col>
            <Col md={6}>
              {/* product detail availble wrapper */}
              <h3 className="mb-2 pdtl-title mt-4 mt-md-0 pt-1 pt-md-0 ">
                {product.title}
              </h3>
              <h6 className="mb-4 pdtl-subtitle">SAE 80W-90 API GL-5</h6>
              <div className="pdtl-avl-wrapper">
                <div className="pdtl-avl-container">
                  {productAvlSizes && (
                    <Fragment>
                      <h5 className="mb-1 font-secondary fw-50">
                        Availble Sizes
                      </h5>
                      <div className="pdtl-avl-data-container">
                        {productAvlSizes.map((el, idx) => {
                          el = el.size.split(" ")
                          const size1 = el[0]
                          const size2 = el[1]
                          return (
                            <div
                              key={uuidv4()}
                              className={`pdtl-avl-data-box cmn-box1 ${
                                idx < 1 ? "active" : ""
                              }`}
                            >
                              <span>{size1}</span>
                              <span>{size2}</span>
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )}
                </div>
                {brochurePdf ? (
                  <a
                    href={`/assets/documents/${brochurePdf}`}
                    className="ct-btn2 mt-5"
                    download
                  >
                    Download Brochure <FaDownload />
                  </a>
                ) : (
                  <div>
                    {showAccordion && product.productDescription.description && (
                      <div className="pinfo-inner-section">
                        <h5>Product Description:</h5>
                        <p className="mb-0">
                          {product.productDescription.description}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* product info section */}
      {showAccordion && brochurePdf && (
        <section className="pinfo-section pinfo-layout-padding">
          <Container>
            <Accordion defaultActiveKey="0">
              {product.productDescription.description && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Product Description</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {product.productDescription.description}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {showProductApplications && (
                <Accordion.Item
                  eventKey={product.productDescription.description ? "1" : "0"}
                >
                  <Accordion.Header>Applications</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      {product.productApplications.productApplications}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </Container>
        </section>
      )}
    </LayoutAnimate>
  )
}

export default ProjectDetails

export const query = graphql`
  query ($slug: String!) {
    allWpProduct(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        productDescription {
          description
        }
        productPackSizes {
          availablePackSizes {
            size
          }
        }
        productApplications {
          productApplications
        }
        productImage {
          productImage {
            sourceUrl
          }
        }
        productSubCategory {
          productSubCategory
        }
        productPackSizes {
          availablePackSizes {
            size
          }
        }
      }
    }
  }
`
